<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("validations") }}</h1>
    <br>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="missions">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
         <!--  <div v-if="user_role !== 'nokiaProject'&& user_role !== 'qhsNokia'&& user_role !== 'teamLeaderSubcontractor'" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData_mission">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Ajouter</span>
          </div> -->
      
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ missions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : missions.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="mission_ref">{{ $t("mission_ref") }}</vs-th>
        <vs-th sort-key="mission">{{ $t("mission") }}</vs-th>
        <vs-th sort-key="journey">{{ $t("journey") }}</vs-th>
        <vs-th sort-key="evacuationPlan">{{ $t("evacuationPlan") }}</vs-th>
        <vs-th sort-key="riskZones">{{ $t("riskZones") }}</vs-th>
        <vs-th sort-key="jobRiskAssessment_step">{{ $t("jobRiskAssessment_step") }}</vs-th>
        <vs-th sort-key="securityEvaluation">{{ $t("securityEvaluation") }}</vs-th>
        <!-- <vs-th sort-key="validate_trajet">{{ $t("validate_trajet") }}</vs-th>
        <vs-th sort-key="mission_status">{{ $t("mission_status") }}</vs-th>
        <vs-th sort-key="created_at">{{ $t("created_at") }}</vs-th>
        <vs-th sort-key="updated_at">{{ $t("updated_at") }}</vs-th> -->
        <vs-th sort-key="qhsNokia">{{ $t("qhsNokia") }}</vs-th>
        <vs-th sort-key="pessNokia">{{ $t("pessNokia") }}</vs-th>
        <vs-th sort-key="leadProject">{{ $t("leadProject") }}</vs-th>
        <vs-th sort-key="action">Action</vs-th>

     </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.poNumber}}</p>
          </vs-td>

          <vs-td>
           <div v-if="tr.missionStep === true" class="flex mb-2">
              <vx-tooltip color="primary" text="Voir la mission">
              <vs-button @click="missionStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
              </vx-tooltip>
              <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia' && user_role !== 'teamLeaderSubcontractor'" text="Carte" color="#595b5c">
                <vs-button @click="carte(tr.id)" color="#595b5c" type="line" class="ml-2" icon-pack="feather" icon="icon-globe"></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>

          <vs-td>
            <div v-if="tr.journeyStep === true" class="flex mb-2">
              <vx-tooltip color="primary" text="Voir le trajet">
              <vs-button  @click="journeyStep_show(tr)" color="primary" size="default" icon-pack="feather" class="ml-2" type="line" icon="icon-eye"></vs-button>    
              </vx-tooltip>
            </div>
          </vs-td>

          <vs-td>          
            <div v-if="tr.evacuationPlanStep === true" class="flex mb-2">
              <vx-tooltip color="primary" text="Voir le plan d'évacuation">
              <vs-button @click="evacuationPlanStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
          
            <div v-if="tr.riskZoneAssessmentStep === true" class="flex mb-2">
            <vx-tooltip  color="primary" text="Voir la zone d'exclusion">
            <vs-button @click="riskZoneAssessmentStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>
            <vx-tooltip v-if="user_role === 'nokiaProject'  || user_role === 'qhsNokia' || user_role === 'pessNokia' || user_role === 'admin' && tr.missionRiskZoneAssessment.length"  :color="getOrderMissionColor(tr.id)" text="Alerte" >
              <vs-button  :color="getOrderMissionColor(tr.id)" size="default" icon-pack="feather" type="line" class="ml-2" icon="icon-alert-triangle"></vs-button>
            </vx-tooltip> 
            </div>
          </vs-td>
          
          <vs-td>
            <div v-if="tr.jobRiskAssessmentStep === true" class="flex mb-2">
            <vx-tooltip color="primary" text="Voir l'evaluation de risque">
            <vs-button  @click="jobRiskAssessmentStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>
          </div>
          </vs-td>

          <vs-td>
            <div v-if="tr.jobRiskAssessmentStep === true && tr.missionSecurityThreatEvaluations.length" class="flex mb-2">
            <vx-tooltip color="primary" text="Voir l'evaluation de risque">
            <vs-button  @click="evalutionSecurityStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>
            </div>
          </vs-td>

          <vs-td>
          <vs-chip v-if="tr.qhsNokiaValidation === 0" :color="getOrderStatusColor1(tr.qhsNokiaValidation)" class="product-order-status">{{'Rejetée'}}</vs-chip> 
          <vs-chip v-if="tr.qhsNokiaValidation === 1"  :color="getOrderStatusColor1(tr.qhsNokiaValidation)" class="product-order-status">{{'Validée'}}</vs-chip> 
          <vs-chip v-if="tr.qhsNokiaValidation === 2"  :color="getOrderStatusColor1(tr.qhsNokiaValidation)" class="product-order-status">{{'Encours'}}</vs-chip> 

        </vs-td>
        <vs-td>
          <vs-chip v-if="tr.pessNokiaValidation === 0" :color="getOrderStatusColor1(tr.pessNokiaValidation)" class="product-order-status">{{'Rejetée'}}</vs-chip> 
          <vs-chip v-if="tr.pessNokiaValidation === 1" :color="getOrderStatusColor1(tr.pessNokiaValidation)" class="product-order-status">{{'Validée'}}</vs-chip> 
          <vs-chip v-if="tr.pessNokiaValidation === 2" :color="getOrderStatusColor1(tr.pessNokiaValidation)" class="product-order-status">{{'Encours'}}</vs-chip> 

        </vs-td>
        <vs-td>
        <vs-chip v-if="tr.leadProjectValidation === 0" :color="getOrderStatusColor1(tr.leadProjectValidation)" class="product-order-status">{{'Rejetée'}}</vs-chip> 
        <vs-chip v-if="tr.leadProjectValidation === 1" :color="getOrderStatusColor1(tr.leadProjectValidation)" class="product-order-status">{{'Validée'}}</vs-chip> 
        <vs-chip v-if="tr.leadProjectValidation === 2" :color="getOrderStatusColor1(tr.leadProjectValidation)" class="product-order-status">{{'Encours'}}</vs-chip> 

        </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div v-if="tr.jobRiskAssessmentStep === true" class="flex">
             <div>
              <vx-tooltip :text="$t('Valider')" color="primary">
                <feather-icon
                  @click="show_mission_open(tr)"
                  style="color: blue"
                  icon="CheckSquareIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="mr-2"
                />
              </vx-tooltip>
             </div>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <!-- fullscreen -->
    <vs-popup
      v-if="show_mission" 
        fullscreen
        class="calendar-event-dialog"
        title="Résumé de la mission"
        :active.sync="show_mission">
        <div class="clearfix">
          <div class="p-base">
              <div Align="Center"><h4 class="font-semibold"><U>RÉSUMÉ MISSION ( {{ ref_mission }} ) </U></h4></div>
              <br>
              <p>
                <span class="font-semibold mr-2">1. Projet : </span><span class="mr-2">
                    {{ projet_ref }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">2. Sites ({{ site_number}}) :</span><span class="mr-2">
                   {{ site_name }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">3. Tâches :</span><span class="mr-2">
                   {{ tache_name }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">4. Profil de risque  :</span><span class="mr-2">
                   {{ profile_risque_name }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">5. Équipe :</span><span class="mr-2">
                   ( {{equipe_persone_valide}} ) personne(s) validée(s)
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">6. Outils :</span><span class="mr-2">
                    {{outils}}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">7. Date prévisionnelle :</span><span class="mr-2">
                   {{ date_previsiionnelle }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">8. Véhicules :</span><span class="mr-2">
                   {{ vehicule }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">9. Longueur du trajet :</span><span class="mr-2">
                    {{  distance_tajet }} ,( {{ number_pause }} ) pause(s) ,{{ ville_pause_tajet }}
                </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">10. Évaluation des zones d'exclusions :</span><span class="mr-2">
                  {{evalutionRiskObject[evaluation_zone]}}
                 </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">11. Évaluation de risques :</span><span class="mr-2">
                   {{ evalution_risque }}     
               </span>
              </p>
              <br>
              <p>
                <span class="font-semibold mr-2">12. Statut des validations :</span><span class="mr-2">
                    {{ statut_validation }}
                </span>
              </p>
            </div>
        </div>
        <div v-if="user_role === 'admin'" class="flex ">

        <div class="flex flex-wrap justify-between" v-if="user_role === leadProjectValidation_action === 2">
        <div v-if="user_role === 'admin' && qhsNokiaValidation_action === 2" >
          <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
          <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
        </div>
        
        <div v-if="user_role === 'admin' && qhsNokiaValidation_action !== 2 && leadProjectValidation_action !==2" >
          <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
          <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
        </div>

        <div v-if="user_role === 'admin' && qhsNokiaValidation_action === 1 && leadProjectValidation_action !==2 " >
          <vs-button :disabled="pessNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_PessNokia(id_mission)" >Valider (Pess nokia)</vs-button>
          <vs-button :disabled="pessNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_PessNokia(id_mission)" >Rejeter (Pess nokia)</vs-button>
        </div>

       
        <div v-if="user_role === 'admin' && pessNokiaValidation_action === 1 " >
          <vs-button :disabled="leadProjectValidation_action === 1"  color="success"  class="float-right mt-4 mr-4" @click="validerMission_LeadProject(id_mission)" >Valider (Chef projet)</vs-button>
          <vs-button :disabled="leadProjectValidation_action === 0"  color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_LeadProject(id_mission)" >Rejeter (Chef projet)</vs-button>
        </div>
        </div>

        <div class="flex flex-wrap justify-between" v-if="user_role === leadProjectValidation_action !== 2">
        
        <div v-if="user_role === 'admin' && pessNokiaValidation_action === 0 && leadProjectValidation_action === 0 " >
          <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
          <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
        </div>

        <div v-if="user_role === 'admin' && qhsNokiaValidation_action === 1 && leadProjectValidation_action === 0  " >
          <vs-button :disabled="pessNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_PessNokia(id_mission)" >Valider (Pess nokia)</vs-button>
          <vs-button :disabled="pessNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_PessNokia(id_mission)" >Rejeter (Pess nokia)</vs-button>
        </div>

       
        <div v-if="user_role === 'admin'&& qhsNokiaValidation_action === 1 && pessNokiaValidation_action === 1 " >
          <vs-button :disabled="leadProjectValidation_action === 1"  color="success"  class="float-right mt-4 mr-4" @click="validerMission_LeadProject(id_mission)" >Valider (Chef projet)</vs-button>
          <vs-button :disabled="leadProjectValidation_action === 0"  color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_LeadProject(id_mission)" >Rejeter (Chef projet)</vs-button>
        </div>
        </div>

        </div>
        
        <div v-if="user_role !== 'admin'">

        <div  v-if="leadProjectValidation_action ===2" >

        <div v-if="user_role === 'qhsNokia' && qhsNokiaValidation_action === 2" >
          <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
          <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
        </div>

        <div v-if="user_role === 'qhsNokia' && qhsNokiaValidation_action !== 2 && leadProjectValidation_action ===2 " >
          <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
          <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
        </div>

    
         <div v-if="user_role === 'pessNokia' && qhsNokiaValidation_action === 1 && leadProjectValidation_action ===2 ">
          <vs-button :disabled="pessNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_PessNokia(id_mission)" >Valider (Pess nokia)</vs-button>
          <vs-button :disabled="pessNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_PessNokia(id_mission)" >Rejeter (Pess nokia)</vs-button>
         </div>

         <div v-if="user_role === 'leadProject' && pessNokiaValidation_action === 1  ">
          <vs-button :disabled="leadProjectValidation_action === 1"  color="success"  class="float-right mt-4 mr-4" @click="validerMission_LeadProject(id_mission)" >Valider (Chef projet)</vs-button>
          <vs-button :disabled="leadProjectValidation_action === 0"  color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_LeadProject(id_mission)" >Rejeter (Chef projet)</vs-button>
         </div>
        </div>

        <div  v-if="leadProjectValidation_action !==2" >
  
          <div v-if="user_role === 'qhsNokia' && pessNokiaValidation_action === 0" >
            <vs-button :disabled="qhsNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_QshNokia(id_mission)" >Valider (Qsh nokia)</vs-button>
            <vs-button :disabled="qhsNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_QshNokia(id_mission)" >Rejeter (Qsh nokia)</vs-button>
          </div>
  
      
           <div v-if="user_role === 'pessNokia' && leadProjectValidation_action === 0 ">
            <vs-button :disabled="pessNokiaValidation_action === 1" color="success"  class="float-right mt-4 mr-4" @click="validerMission_PessNokia(id_mission)" >Valider (Pess nokia)</vs-button>
            <vs-button :disabled="pessNokiaValidation_action === 0" color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_PessNokia(id_mission)" >Rejeter (Pess nokia)</vs-button>
           </div>
  
           <div v-if="user_role === 'leadProject' && pessNokiaValidation_action === 1  ">
            <vs-button :disabled="leadProjectValidation_action === 1"  color="success"  class="float-right mt-4 mr-4" @click="validerMission_LeadProject(id_mission)" >Valider (Chef projet)</vs-button>
            <vs-button :disabled="leadProjectValidation_action === 0"  color="danger"  class="float-right mt-4 mr-4" @click="RejeterMission_LeadProject(id_mission)" >Rejeter (Chef projet)</vs-button>
           </div>
        </div>
        
        </div>
    </vs-popup>
  </div>
</template>
<script>

export default {
  data () {
    return {
      id_mission : '',
      qhsNokiaValidation_action:false,
      pessNokiaValidation_action:false,
      leadProjectValidation_action:false,
      ref_mission:'',
      projet_ref:'',
      site_number:'',
      site_name:'',
      tache_name:'',
      profile_risque_name:'',
      equipe_persone_valide:'',
      outils : '',
      date_previsiionnelle:'',
      vehicule:'',
      distance_tajet:'',
      ville_pause_tajet:'',
      number_pause:'',
      evaluation_zone:'',
      evalution_risque:'',
      statut_validation:'',
      show_mission : false,
      validationObject: {
        false: 'En cours',
        true: 'Cloturée'
      },
      trajetObject: {
        false: 'Rejetée',
        true: 'Validée'
      },
      evalutionRiskObject: {
        red: 'Point critiques à considérer',
        orange: 'Point de virgilance à considérer',
        green:'Rien à signaler'
      },
      produits_computed_show : [],
      activePrompt1 : '',
      user_role : '',
      missions:[],
      objResult:[],
      isMounted: false,
      itemsPerPage: 20
    }
  },

  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.missions.length
    }
  },

  methods: {
    show_mission_open (tr) {
      this.id_mission = tr.id
      this.qhsNokiaValidation_action = tr.qhsNokiaValidation
      this.pessNokiaValidation_action = tr.pessNokiaValidation
      this.leadProjectValidation_action = tr.leadProjectValidation
      const transform = {0 :'Non validé', 1 :'Validé', 2 : 'Encours'}
      this.evalution_risque = 'Rien à signaler' 
      this.ref_mission = tr.poNumber
      this.projet_ref = tr.project.name
      this.site_number = tr.sites.length
      this.site_name = tr.sites.map(site => site.name).join(', ')
      this.tache_name = tr.tasks.map(task => task.name).join(', ')      
      this.profile_risque_name = tr.riskProfiles.map(riskProfile => riskProfile.name).join(', ')
      this.equipe_persone_valide = tr.team.staffs.filter(staff => staff.activated === 1).length
      const { compteurPoulie, compteurCorde, compteurSangle, compteurMousqueton, compteurBalise, compteurTreuil} = tr.team.liftingEquipments.reduce((acc, equipment) => {
        if (equipment.equipmentType.name === 'poulie') {
          acc.compteurPoulie++
        } else if (equipment.equipmentType.name === 'corde') {
          acc.compteurCorde++
        } else if (equipment.equipmentType.name === 'sangle') {
          acc.compteurSangle++
        } else if (equipment.equipmentType.name === 'mousqueton') {
          acc.compteurMousqueton++
        } else if (equipment.equipmentType.name === 'balise') {
          acc.compteurBalise++
        } else if (equipment.equipmentType.name === 'treuil') {
          acc.compteurTreuil++
        }
        return acc
      }, { compteurPoulie: 0, compteurCorde: 0, compteurSangle: 0, compteurMousqueton: 0, compteurBalise: 0, compteurTreuil: 0 })
      this.outils = `( ${compteurPoulie} ) Poulie(s) , ( ${compteurCorde} ) Corde(s) , ( ${compteurSangle} ) Sangles(s) ,
       ( ${compteurMousqueton} ) Mousqueton(s) , ( ${compteurBalise} ) Balise(s) , ( ${compteurTreuil} ) Treuil(s)`
      this.date_previsiionnelle = `Du ${tr.startDate} au ${tr.endDate}`
      const plaquesActives = tr.team.vehicles.reduce((acc, vehicle) => {
        if (vehicle.activated === 1) {
          acc.push(vehicle.plateNumber)
        }
        return acc
      }, [])
      this.vehicule = plaquesActives.join(', ')
      this.distance_tajet = `${tr.missionJourney[0].distance} km`
      this.ville_pause_tajet = tr.missionJourney[0].breacks.map(sites => sites.site).join(' et ')
      this.number_pause = tr.missionJourney[0].breacks.length
      this.statut_validation = `${transform[tr.qhsNokiaValidation]} QHS > ${ transform[tr.pessNokiaValidation]} PESS > ${ transform[tr.leadProjectValidation]} LEAD `
      this.evaluation_zone = this.getOrderMissionColor1(tr.id)
      this.jobRiskaverage(tr.missionJobRiskAssessment[0].jobRiskAssessmentHasRisks)
      this.produits_computed_show.forEach(item => { 
        if (item.residualRisk >= 15) { 
          this.evalution_risque = 'Point de vigilance' 
        }
      })
      this.show_mission = true
    },
    jobRiskaverage (data) {
      this.produits_computed_show = []
      this.repProduit = []
      const z = data.length
      for (let i = 0; i < z; i++) {
        const obj = {          
          residualRisk: ''
        }
        obj.residualRisk = data[i].residualRisk
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.produits_computed_show.push(this.repProduit[j])
      }
    },
    carte (id) {
      this.$http.get(`generate_mission_url/${id}/`)
        .then(response => {
          const url = response.data.url
          window.open(url, '_blank')
        })
        .catch(() => {
          this.$vs.notify({
            time: 4000,
            title: 'ATTENTION',
            text: 'Opération échouée',
            color: 'warning',
            position: 'top-center'
          })       
        })
    },
    getOrderMissionColor (status) {
      const obj = this.objResult.find(item => item.mission_id === status)
      return obj ? obj.valeurColor : null
    },

    getOrderMissionColor1 (status) {
      const obj = this.objResult.find(item => item.mission_id === status)
      return obj ? obj.valeurColor : null
    },

    getOrderStatusColor (status) {
      if (status === false) {
        return 'warning'
      } else if (status === true) {
        return 'success'
      } else {
        return 'danger'

      } 
    },
    getOrderStatusColor1 (status) {
      if (status === 0) {
        return 'danger'
      } else if (status === 1) {
        return 'success'
      } else if (status === 2) {
        return 'warning'
      }
    },
    planification () {
      this.$router.push('/Planifications').catch(() => {})
    },
    missionStep () {
      this.missionControle = 2
      this.activePrompt1_mission = 1
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_mission = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_editData (tr) {
      this.missionControle = 2
      this.activePrompt1_mission = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_confirmation_show (tr) {
      this.missionControle = 2
      this.activePrompt1_mission = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    journeyStep (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_editData (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_plus (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    evacuationPlanStep (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    evacuationPlanStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    evacuationPlanStep_editData (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    riskZoneAssessmentStep (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    riskZoneAssessmentStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    riskZoneAssessmentStep_editData (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    jobRiskAssessmentStep (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_editData (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_ValidateData (tr) {
      this.missionControle = 2
      this.activePrompt1_journey = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    evalutionSecurityStep_show (tr) {
      this.missionControle = 2
      this.activePrompt1_EvalutionSecurity = 5
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvalutionSecurity').catch(() => {})
    },
    addNewData_mission () {
      this.missionStep()
    },
 
    validerMission_QshNokia (mission) {
      this.show_mission = false
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation mission',
        text: 'Confirmer la validation de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('qhs-validate-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission comfirmée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    validerMission_PessNokia (mission) {
      this.show_mission = false
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation mission',
        text: 'Confirmer la validation de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('pess-validate-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission validée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    validerMission_LeadProject (mission) {
      this.show_mission = false
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation mission',
        text: 'Confirmer la validation de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('leadProject-validate-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission validée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterMission_QshNokia (mission) {
      this.show_mission = false

      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet mission',
        text: 'Confirmer le rejet de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('qhs-reject-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission rejetée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterMission_PessNokia (mission) {
      this.show_mission = false
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet mission',
        text: 'Confirmer le rejet de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('pess-reject-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission rejetée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterMission_LeadProject (mission) {
      this.show_mission = false
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet mission',
        text: 'Confirmer le rejet de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('leadProject-reject-mission/', {mission})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Mission rejetée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    validerTrajet (tr) {
      this.show_mission = false
      const journey = tr.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation trajet',
        text: 'Confirmer la validation? ',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {journey})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Trajet validé', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterTrajet (tr) {
      this.show_mission = false
      const journey = tr.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet trajet',
        text: 'Confirmer le rejet?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {journey})
            .then(response => {
              this.getAllMissions()
              this.show_mission = false
              window.getPrendTaCom.success('Trajet rejeté', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    deleteData_mission (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`missions/${id}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('La mission est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_evacuationPlan (id) {
      const id_ = id.missionEvacuationPlan[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression du plan d\'évacuation?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`evacuationPlans/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Le plan est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_journeys (id) {
      const id_ = id.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression du trajet?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`journeys/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Le trajet est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression du trajet a échoué.' })
            })
        }
      })

    },
    deleteData_riskZoneAssessment (id) {
      const id_ = id.missionRiskZoneAssessment[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de la zone d\'exclusion',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`riskZoneAssessments/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('L\'évaluations de la zone de risque est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_JobRiskAssessment (id) {
      const id_ = id.missionJobRiskAssessment[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de l\'évaluations des risques?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`jobRiskAssessments/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('L\' évaluations des risques d\'emploi est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    getAllMissions () {
      this.$vs.loading()
      this.$http.get('missions/')
        .then((response) => {
          this.objResult = []
          let finale_color = ''
          let stat_yellow = ''
          let stat_red = ''
          const obj_mission_Risk = {
            id_mission : [],
            danger_obj : []
          }
          const obj_mission_Risk_end = {
            id_mission_end : [],
            danger_obj_end : []
          }
          this.missions = response.data
          const results = response.data
          results.forEach(event => {
            if (event.missionRiskZoneAssessment.length) {
              obj_mission_Risk.id_mission.push(event.id)
              obj_mission_Risk.danger_obj.push(event.missionRiskZoneAssessment[0].questionHasFileHasSites)
            }
        
          })
          if (obj_mission_Risk.id_mission.length) {
            for (let i = 0; i < obj_mission_Risk.id_mission.length; i++) {
              this.danger_obj_end = []
              const risk_obj = obj_mission_Risk.danger_obj[i]
              risk_obj.forEach(event => {
                const obj = {
                  mission_id : obj_mission_Risk.id_mission[i],
                  conditionFaux:event.conditionFaux,
                  conditionNa:event.conditionNa,
                  conditionVrai:event.conditionVrai,
                  colorVrai:event.question.colorVrai,
                  //colorNa:event.question.colorNa,
                  colorFaux:event.question.colorFaux,
                  description:event.question.description,
                  site_name:event.site.name
                //question:event.question,
                //site:event.site
                } 
                this.danger_obj_end.push(obj)
              })  
              obj_mission_Risk_end.id_mission_end.push(obj_mission_Risk.id_mission[i])  
              obj_mission_Risk_end.danger_obj_end.push(this.danger_obj_end)          
            }
          
          }
          if (obj_mission_Risk_end.id_mission_end.length) {
            for (let t = 0; t < obj_mission_Risk_end.id_mission_end.length; t++) {
              const obj_normale = {
                mission_id :'',
                valeurColor : ''
              } 
            
              let couleurFaux_red = 0
              let couleurFaux_yellow = 0
              let couleurVrai_red = 0
              let couleurVrai_yellow = 0


              for (let y = 0; y < obj_mission_Risk_end.danger_obj_end.length; y++) {
                const obj_var = obj_mission_Risk_end.danger_obj_end[y]
                for (let z = 0; z < obj_var.length; z++) {
                  if (obj_mission_Risk_end.id_mission_end[t] === obj_var[z].mission_id) {

                    if (obj_var[z].conditionFaux === true) {
                    //countFaux++
                      if (obj_var[z].colorFaux === 'yellow') {
                        couleurFaux_yellow++
                      }
                      if (obj_var[z].colorFaux === 'red') {
                        couleurFaux_red++
                      }
                    }

                    if (obj_var[z].conditionVrai === true) {
                    //countVrai++
                      if (obj_var[z].colorVrai === 'yellow') {
                        couleurVrai_yellow++
                      }
                      if (obj_var[z].colorVrai === 'red') {
                        couleurVrai_red++
                      }
                    } 
                  }
                }
              
              }
              stat_yellow = couleurFaux_yellow + couleurVrai_yellow
              stat_red =  couleurFaux_red + couleurVrai_red

              if (stat_yellow < stat_red) {
                finale_color = 'red'
              } else if (stat_red < stat_yellow) {
              //yellow
                finale_color = 'orange'
              } else if (stat_red === stat_yellow) {
                finale_color = 'red'
              } else if (stat_red === 0 && stat_yellow === 0) {
                finale_color = 'green'
              } else if (stat_red === 0 && stat_yellow > 1) {
              //yellow
                finale_color = 'orange'
              } else {
                finale_color = 'red'
              }      
              obj_normale.mission_id = obj_mission_Risk_end.id_mission_end[t]
              obj_normale.valeurColor = finale_color
              this.objResult.push(obj_normale)
            }
          }
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },

  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    window.Planifications = this
    this.planification_mission_data = {}
    this.getAllMissions()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style scoped>

</style>
